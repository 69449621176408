import { LOCALES } from "../constants";

// "nameOfThePage_namwOfTheWord": word
export default {
    [LOCALES.ENGLISH]: {
        adminHome_menu: "MENU",
        adminHome_menuTopicOfMonth: "TopicOfMonth",
        adminHome_menuMediathek: "Mediathek",
        adminHome_menuMediathekSubMmenu1: "General",
        adminHome_menuMediathekSubMmenu2: "Rubrics settings",
        adminHome_menuMediathekSubMmenu3: "Mediathek",
        adminHome_menuMeditation: "Meditation",
        adminHome_menuMeditationSubMmenu1: "General",
        adminHome_menuMeditationSubMmenu2: "Rubric Settings",
        adminHome_menuMeditationSubMmenu3: "Meditation",
        adminHome_menuMagazin: "Magazine",
        adminHome_menuMagazinSubMmenu1: "General",
        adminHome_menuMagazinSubMmenu2: "Article",

        themaDesMonats_title: "Welcome to the topic of the month",
        themeDesMonth_userMode: "User Mode",
        themeDesMonths_adminMode: "Admin Mode",
        themaDesMonats_description: "Here you will find a new topic every month. At the beginning of each month we unlock a new world for you. Here you can find our masterclasses, meditations, affirmations and exercises, live and for download. Every month you can upload your own photo and write your goals and affirmations into it. You can even write a letter to yourself, which we will send you six weeks later",
        themaDesMonats_topicStatus: "The theme of the month is",
        topicDesMonth_topicStatusReleased: "Published",
        topicDesMonths_topicStatusUnpublished: "Unpublished",
        themaDesMonats_textColor: "Text color e.g. #000000 or rgba(255, 255, 255, 1)",
        themaDesMonats_backgrounColor: "Background color e.g. #000000 or rgba(255, 255, 255, 1)",
        themaDesMonats_emailHeaderImage: "Image for email header",
        themaDesMonats_homePageImage: "Image for home page",
        themaDesMonats_save: "save",
        themaDesMonats_ToastErrorTitle: "Error!",
        themaDesMonats_ToastSuccessTitle: "Success!.",
        themaDesMonth_ToastError: "Error, data was not saved.",
        themeDesMonats_ToastSuccess: "Data was successfully saved.",
        themeDesMonats_boxTitle: "Edit Box.",
        themeDesMonats_boxOption: "Box Options.",
        themeDesMonats_save: "Save",
        themeDesMonats_cancel: "Cancel",
        themeDesMonats_boxdelete: "Delete Box.",
        themeDesMonats_sizeLabel: "Size: Header",
        themeDesMonats_template: "Select Template",
        themeDesMonats_templateLabel: "Template: Image",
        themeDesMonats_position: "position",
        themeDesMonats_ClearImage: "Clear Image",

        mediathek_generalHighlightVideo: "Highlight Videos",
        mediathek_generalHighlightVideoTitle: "Title of the video",
        mediathek_generalHighlightVideoSelect: "Select video",
        mediathek_highlightVideoBtnInsert: "Insert",
        mediathek_highlightVideoBtnAlert: "This video is already in use",

        mediathek_generalPopularVideo: "Most popular videos",
        mediathek_generalPopularVideoTitle: "Most Popular Videos",
        mediathek_generalPopularVideoSelect: "Select Video",
        mediathek_generalPopularVideoBtnInsert: "Insert",
        mediathek_generalPopularVideoBtnAlert: "This video is already in use",

        mediathek_generalRecommendedVideo: "Recommended Videos",
        mediathek_generalRecommendedVideoTitle: "You might like that",
        mediathek_generalRecommendedVideoSelect: "Select video",
        mediathek_generalRecommendedVideoBtnInsert: "Insert",
        mediathek_generalRecommendedVideoBtnAlert: "This video is already in use",

        mediathek_generalNewestVideo: "Latest videos",
        mediathek_generalNewestVideoTitle: "Recent Videos",
        mediathek_generalNewestVideoSelect: "Select Video",
        mediathek_generalNewestVideoBtnInsert: "Insert",
        mediathek_generalNewestVideoBtnAlert: "This video is already in use",

        mediathek_generalTagSet: "TagSets",
        mediathek_generalTags: "Tags",
        mediathek_generalTagsTitle: "Tags",
        mediathek_generalTagsInput: "TagInput",
        mediathek_generalTagsBtn: "Create",

        mediathek_generalRubrics: "Rubrics",
        mediathek_generalRubricsTitle: "Highlight Rubrics",
        mediathek_generalRubricsSelect: "Select Rubric",
        mediathek_generalRubricsBtnInsert: "Insert",
        mediathek_generalRubricsBtnAlert: "This video is already in use",

        mediathek_rubricsSettingAddSection: "Add Section",
        mediathek_rubricsSettingAddSectionModal: "Add new section",
        mediathek_rubricsSettingAddSectionTitle: "Title",
        mediathek_rubricsSettingAddSectionNbelement: "Element numbers",
        mediathek_rubricsSettingAddSectionSave: "Save",
        mediathek_rubricsSettingAddSectionLoad: "Being loaded...",
        mediathek_rubricsSettingAddSectionCancel: "Cancel",
        mediathek_rubricsSettingAddSectionEmpty: "Please specify a title and the number of items you want to display.",
        mediathek_rubricsSettingAddSectionError: "Error!",

        mediathek_mediathekRubrics: "Rubrics",
        mediathek_mediathekRubricsTitle: "TITLE",
        mediathek_mediathekRubricsStatus: "STATUS",
        mediathek_mediathekRubricsPublish: "Published",
        mediathek_mediathekRubricsUnpublish: "Unpublished",
        mediathek_mediathekRubricsSortDesc: "SHORTDESCRIPTION",
        mediathek_mediathekRubricsAction: "EDIT",
        mediathek_mediathekRubricsSearch: "SEARCH",
        mediathek_mediathekRubricsFilter: "filter",
        mediathek_mediathekVideoShortDesc: "Short Description",
        mediathek_mediathekVideoDesc: "Description",
        mediathek_mediathekVideoRelatedDesc: "Related Description",
        mediathek_mediathekVideoDuration: "Duration",
        mediathek_mediathekVideoImage: "Image",
        mediathek_mediathekVideoDelImage: "DeleteImage",
        mediathek_mediathekVideoAttachments: "Attachments",
        mediathek_mediathekVideoReferences: "References",
        mediathek_mediathekVideoUrl: "Video Url",
        mediathek_mediathekVideoSave: "Save",
        mediathek_mediathekVideoCancel: "Cancel",

        mediathek_mediathekVideoAddSection: "Create Section",
        mediathek_mediathekVideoAddTitle: "Title",
        mediathek_mediathekVideoAddDesc: "Description",
        mediathek_mediathekVideoAddTags: "tags",
        mediathek_mediathekVideoAddImage: "Image",
        mediathek_mediathekVideoAddDelImage: "DeleteImage",
        mediathek_mediathekVideoAddSave: "Save",
        mediathek_mediathekVideoAddCancel: "Cancel",

        medidation_generalHighlightVideo: "Highlight Videos",
        medidation_generalHighlightVideoTitle: "Highlight Videos",
        medidation_generalHighlightVideoSelect: "Select a video",
        medidation_generalhighlightVideoBtnInsert: "Insert",
        medidation_generalhighlightVideoBtnAlert: "This video is already in use",

        medidation_generalPopularVideo: "Most popular videos",
        medidation_generalPopularVideoTitle: "Most Popular Videos",
        medidation_generalPopularVideoSelect: "Select a video",
        medidation_generalPopularVideoBtnInsert: "Insert",
        medidation_generalPopularVideoBtnAlert: "This video is already in use",

        medidation_generalRecommendedVideo: "Recommended Videos",
        medidation_generalRecommendedVideoTitle: "Title of the video",
        medidation_generalRecommendedVideoSelect: "Select a video",
        medidation_generalRecommendedVideoBtnInsert: "Insert",
        medidation_generalRecommendedVideoBtnAlert: "This video is already in use",

        medidation_generalNewestVideo: "Latest videos",
        medidation_generalNewestVideoTitle: "Recent Videos",
        medidation_generalNewestVideoSelect: "Select a video",
        medidation_generalNewestVideoBtnInsert: "Insert",
        medidation_generalNewestVideoBtnAlert: "This video is already in use",

        medidation_generalTags: "Tags",
        medidation_generalTagsTitle: "Tags",
        medidation_generalTagsInput: "TagInput",
        medidation_generalTagsBtn: "Create",

        medidation_generalRubrics: "Rubrics",
        medidation_generalRubricsTitle: "Highlight Rubrics",
        medidation_generalRubricsSelect: "Select Rubric",
        medidation_generalRubricsBtnInsert: "insert",
        medidation_generalRubricsBtnAlert: "this video is already used",

        medidation_rubricsSettingAddSection: "add section",
        medidation_rubricsSettingAddSectionTitle: "Title",
        medidation_rubricsSettingAddSectionNbelement: "position",
        medidation_rubricsSettingAddSectionSave: "Save",
        medidation_rubricsSettingAddSectionCancel: "Cancel",
        medidation_rubricsSettingAddSectionModal: "Add new section",
        medidation_rubricsSettingAddSectionLoad: "Will be loaded...",
        medidation_rubricsSettingAddSectionEmpty: "Please specify a title and the number of elements you want to display.",
        medidation_rubricsSettingAddSectionError: "Error!",
        medidation_MedidationUpdateSection: "Update section.",
        medidation_MedidationRemoveSection: "Delete section",

        medidation_MedidationRubrics: "rubrics",
        medidation_MedidationVideo: "video",
        medidation_MedidationVideoShortDesc: "Short Description",
        medidation_MedidationVideoDesc: "Description",
        medidation_MedidationVideoRelatedDesc: "Related Description",
        medidation_MedidationVideoDuration: "Duration",
        medidation_MedidationVideoImage: "Image",
        medidation_MedidationVideoDelImage: "DeleteImage",
        medidation_MedidationVideoAttachments: "attachments",
        medidation_MedidationVideoReferences: "References",
        medidation_MedidationVideoUrl: "video url",
        medidation_MedidationVideoSave: "Save",
        medidation_MedidationVideoCancel: "Cancel",

        meditation_meditationVideoAddSection: "Create Section",
        meditation_meditationVideoAddTitle: "Title",
        meditation_meditationVideoAddDesc: "Description",
        meditation_meditationVideoAddTags: "Tags",
        meditation_meditationVideoAddImage: "Image",
        meditation_meditationVideoAddDelImage: "Delete Image",
        meditation_meditationVideoAddSave: "Save",
        meditation_meditationVideoAddCancel: "Cancel",

        magazin_generalHighlightedArticles: "Highlight Article",
        magazin_generalHighlightedArticlesTitle: "Highlight Article",
        magazin_generalHighlightedArticlesSelect: "Select Article",
        magazin_generalHighlightedArticlesBtnInsert: "Insert",
        magazin_generalHighlightedArticlesBtnAlert: "This video is already used",

        magazin_header_1: "Magazine",
        magazin_header_2: "create",
        magazin_title: "Title",
        magazin_generalSuggestedArticles: "SuggestedArticles",
        magazin_generalSuggestedArticlesTitle: "Suggested Articles",
        magazin_generalSuggestedArticlesSelect: "Select Articles",
        magazin_generalSuggestedArticlesBtnInsert: "Insert",
        magazin_generalSuggestedArticlesBtnAlert: "This video is already used",

        magazin_generalMostViewedArticles: "Most Popular Articles",
        magazin_generalMostViewedArticlesTitle: "Most Popular Articles",
        magazin_generalMostViewedArticlesSelect: "Select Articles",
        magazin_generalMostViewedArticlesBtnInsert: "Insert",
        magazin_generalMostViewedArticlesBtnAlert: "This video is already in use",

        magazin_generalTags: "Tags",
        magazin_generalTagsTitle: "Tags",
        magazin_generalTagsInput: "TagInput",
        magazin_generalTagsBtn: "Create",

        magazin_articleTitle: "Article",
        magazin_articleSearch: "Search",
        magazin_articleFilter: "Filter",
        magazin_articleFilterLabel: "All",
        magazin_articleFilterStatusPublished: "Published",
        magazin_articleFilterStatusUnpublished: "Unpublished",

        magazin_articleCreateSection: "WriteArticle",
        magazin_articleCreateTitle: "Title",
        magazin_articleCreateReadingTime: "Reading Time",
        magazin_articleCreateIndex: "Index",
        magazin_articleCreateBriefDesc: "Brief Description",
        magazin_articleCreateDesc: "Description",
        magazin_articleCreateTags: "Tags",
        magazin_articleCreateRubrics: "Rubrics",
        magazin_articleCreateAuthors: "Authors",
        magazin_articleCreateNote: "NOTE: You can't upload images, videos and audios until the article is saved for the first time.",
        magazin_articleCreateSave: "Save.",
        magazin_articleCreateCancel: "Cancel",
        magazin_article_public: "Public.",
        magazin_article_private: 'Private',

        adminLogin_welcome: "Welcome to Happiness House!",
        adminLogin_note: "Please log in to your account and start the adventure!",
        adminLogin_email: "Email",
        adminLogin_password: "Password",
        adminLogin_passwordForget: "Forgot your password?",
        adminLogin_remember: "stay logged in",
        adminLogin_signIn: "Sign in",
        adminLogin_account: "Are you new with us?",
        adminLogin_accountLink: "Create an account",
        adminLogin_signSuccesscClient: "You have successfully logged in to Vuexy as a client user. You can start exploring now. Have fun!",
        adminLogin_signSuccesscAdmin: "You have successfully signed in to Vuexy as an admin user. You can start exploring now. Have fun!",
        adminLogin_welcomeUser: "Welcome.",

        academy_generalHighlightedCourses: "HighlightCourses",
        academy_generalHighlightedTag: "tags",
        academy_courseBtn: "insertion",
        academy_TagBtn: "insertion",
        academy_action: "ACTION",
        academy_course: "courses",
        academy_title: "TITLE",
        academy_description: "Description",
        academy_shortDescription: "Short Description",
        academy_relatedDescription: "Related Description",
        academy_Duration: "Duration",
        academy_Image: "Image",
        academy_DelImage: "Delete Image",
        academy_largeImage: "Large Image",
        academy_Attachments: "Attachments",
        academy_References: "References",
        academy_Url: "Video URL",
        academy_Save: "Enregistrer",
        academy_Cancel: "Cancel",

        expert_title: "Expert",
        expert_position: "Position",
        expert_firstname: "FirstName",
        expert_lastname: "LastName",
        expert_role: "Role",
        expert_shortDesc: "Short Description",
        expert_description: "Description",
        expert_statu: "STATUS",
        expert_action: "EDIT",
        expert_statuActive: "Activated",
        expert_statuDesactived: "Disabled",
        expert_save: "Save",
        expert_cancel: "Cancel",
        expert_Search: "Search",
        expert_Filter: "Filter",
        expert_childrenImage: "ChildImage",
        expert_currentImage: "Current Image",
        expert_delimage: "Delete image",

        academy_sideBarTitle: "Academy",
        academyGeneral_sideBarTitle: "General",
        academyRubricsSettings_sideBarTitle: "Rubric Settings",
        dashboard_sideBarTitle: "Dashboard",
        mediathek_sideBarTitle: "mediathek",
        mediathekGeneral_sideBarTitle: "General",
        mediathekRubricsSettings_sideBarTitle: "RubricSettings",
        meditation_sideBarTitle: "Meditation",
        meditationGeneral_sideBarTitle: "General",
        meditationRubricsSettings_sideBarTitle: "Rubric Settings",
        meditationRubricsSettings_sideBarTitle: "Theme of the month",
        magazin_sideBarTitle: "Magazine",
        magazinArticle_sideBarTitle: "Article",
        magazinGeneral_sideBarTitle: "General",
        expert_sideBarTitle: "Experts/Authors",

        live_events_datatable_header: "Events",
        live_events_datatable_sub_header: "Create",
        live_events_datatable_search: "Search",
        live_events_datatable_filter: "Filter",
        live_events_datatable_type: "select live event type",
        live_events_datatable_noData: "There are no records",
        live_events_datatable_image: "IMAGE",
        live_events_datatable_title: "TITLE",
        live_events_datatable_category: "CATEGORY",
        live_events_datatable_description: "SHORT_DESCRIPTION",
        live_events_datatable_attendees: "PARTICIPANTS",
        live_events_datatable_start: "START_DATE",
        live_events_datatable_status: "STATUS",
        live_events_datatable_action: "ACTIONS",
        live_events_event_title: "event_title",
        live_events_event_internalTitle: "internal_name",
        live_events_event_description: "EventDescription",
        live_events_event_long_description: "Long Description",
        live_events_event_duration: "Event Duration",
        live_events_event_videoLink: "Video Link",
        live_events_event_released: "Release Event",
        live_events_event_public: "Public stream",
        live_events_event_notifyUser: "Send reminder email to all users",
        live_events_event_weekly: "Weekly event",
        live_events_event_highlighted: "Highlighted",
        live_events_event_image: "image",
        live_events_event_delete_image: "Delete Image",
        live_events_event_submit: "Save",
        live_events_event_cancel: "Cancel",
        live_events_event_category: "Category",
        live_events_event_attendees: "Attendees",
        live_events_event_start_dt: "Start_Date",
        live_events_event_end_dt: "End Date",
        live_events_rubric_title: "Rubric Title",
        live_events_rubric_description: "Short Description",
        live_events_rubric_published: "Published",
        live_events_rubric_header: "Rubrics",
        live_events: "Live Events",

        community_sideBarTitle: "Community",
        community_groups_create: "Create",
        community_groups_update: "Update",
        community_groups: "Groups",
        community_groups_image: "Image",
        community_groups_background_image: "Cover_image",
        community_groups_name: "name",
        community_groups_description: "Description",
        community_groups_members: "Members",
        community_groups_type: "type",
        community_groups_is_private: "Is the group private?",
        community_groups_private: "Private group",
        community_groups_public: "Public group",
        community_groups_action: "Action",
        community_groups_tag: "Keywords",
        community_groups_request_join_date: "Join date",
        community_group_request_join_reason: "reason",
        community_groups_request_join_request: "join_request",
        fileDropzone_photoPerDragDropHere: "drag and drop photos here",
        fileDropzone_orPickFiles: "or pick files",

        login_email: "email",
        login_password: "Password",
        login_signIn: "Login",
        login_welcome: "Welcome",
        login_forgotPassword: "Forgot password",
        login_pleaseSignInToYourAccountAndStartTheAdventure: "Please sign in to your account and start the adventure",
        login_passwordNeedToBeChanged: "This is your first login and the password needs to be changed.",
        login_changePassword: "Confirm",
        login_IHaveDie: "I have read the terms of use",
        login_logged_in: "Stay logged in",

        passwordForget_email: "Email",
        passwordForget_sendResetLink: "Send link to reset",
        passwordForget_backToLogin: "Return to login",
        passwordForget_forgetPassword: "Forgot password",
        passwordForget_password: "Password",
        passwordForget_newPassword: "New password",
        passwordForget_changePassword: "Password change",
        passwordForget_receivedCode: "Code received",
        passwordForget_codeSent: "The code was sent by e-mail",
        passwordForget_passwordHasBeenReset: "The password has been changed",
        passwordForget_sendCode: "Send code",
        Live_events_Category_all: "All categories",
        Live_events_Category_1: "Live Stream",
        Live_events_Category_2: "Live Video",
        Live_events_Category_3: "Live Chat",
        Live_events_Category_4: "Zoom Event",

        Video_chapter: "Video_Chapter",
        Video_chapter_add: "Add a new chapter",

        community_abuses: "Abuse",
        community_group: "Groups",
        Users: "users",
        Posts: "posts",
        comment: "comments",
        community_reporter: "Author",
        community_report_date: "date of post",
        community_status: "Status",
        community_owner_object: "owner object",
        community_owner_domain: "owner domain",
        community_domain: "category",
        community_report: "Report",
        community_content: "content",
        community_reason: "reason",
        community_resolve_abuse: "RESOLVE ABUSE",
        community_reported_comment: "Reported comment",
        community_like_number: "Number of likes",
        community_replies_number: "number of replies",
        community_comment_author: "Author of the comment",
        community_resolve: "Resolve",
        community_resolve_abuse_post: "Report abuse post",
        community_resolve_abuse_data: "Abuse details",
        community_reported_post: "Reported post",
        community_post_author: "Author of the post",
        community_title: "Title",

        delete_confirmation_title: "Do you really want to delete this content?",
        delete_confirmation_text: "The deletion is irrevocable and cannot be undone.",
        delete_confirmation_button_text: "Yes, please delete",
        community_content_deleted: "(Deleted content)",
        community_delete_comment: "delete comment",
        community_delete_post: "Delete post",
        community_post_title: "Post title",
        community_post_status: "status of the post",
        community_user_reported: "Reported member",
        community_creation_date: "Creation date",
        community_post_list: "List of posts",
        community_select_a_group: "Select group",
        community_search_a_post: "Search post",

        community_groups_user_search: "Search",
        community_groups_user_add_title: "Add new member",
        community_groups_user_add_button: "Add",
        community_groups_no_member: "No member found",
        community_groups_manage_title: "Managing group members: ",
        community_groups_manage_body: "List of group members",
        community_groups: "Groups",
        community_requests: "group_requests",
        community_posts: "posts",
        No_tags: "No tags",
        No_reason: "No reason",
        approved: "Approved",
        cant_manage: "You can't manage your own request",

        magazin_article_header_1: "article",
        magazin_article_header_2: "Article",
        magazin_article_title: "Title",
        magazin_article_duration: "reading time",
        magazin_article_index: "Index",
        magazin_article_pubished: "Published",
        magazin_article_rubric: "Rubric",
        magazin_article_auteur: "Authors",
        magazin_article_short_description: "Short description",
        magazin_article_description: "Description",
        magazin_article_tips_1: "You can start writing the article only after the article has been saved for the first time.",
        magazin_article_cover_picture: "cover_picture",
        magazin_article_delete_picture: "delete photo",
        magazin_article_delete_audio: "delete audio",
        magazin_article_attachment: "Attachments",
        magazin_article_reference: "references",
        magazin_article_soumettre: "Save",
        magazin_article_annuler: "Cancel",
        daily_mails_sideBarTitle: "Daily Mails",
        daily_mails_dt: "Date",
        daily_mails_message: "Daily Message",
        daily_mails_message: "News",
        daily_mails_date: "Date",
        daily_mails_name: "Internal name",
        daily_mails_headline: "Headline",
        daily_mails_search: "Search",
        daily_mails_month: "month",
        daily_mails_year: "year",
        daily_mails_category: "category",
        daily_mails_message: "Posts",
        daily_mails_content: "Content",
        daily_mails_signature: "Signature",
        daily_mails_citation: "Citation",
        daily_mails_image: "Image",
        daily_maessage: "Title",
        daily_maessage_title: "Post of the day",
        good_news_sideBarTitle: "Good News",
        treffpunkte_sideBarTitle: "Treffpunkte",
        january: "January",
        february: "February",
        march: "March",
        april: "April",
        may: "May",
        june: "June",
        july: "July",
        august: "August",
        september: "September",
        october: "October",
        november: "November",
        december: "December",
        registrationDate: "registration date",

        chat_sideBarTitle: "chat",
        chat_chatInfo: "broadcast channel",
        chat_dataTable_name: "name",
        chat_dataTable_members: "Members",
        chat_dataTable_status: "status",
        chat_dataTable_activated: "Activated",
        chat_dataTable_desactivated: "Disabled",
        chat_channelModal_isActive: "Is active?",
        chat_channelModal_full_name: "Full name",
        chat_channelModal_add_member: "Add member",
        chat_sidemode_messages: "Messages",
        chat_messageDataTable_message: "Message",
        chat_modalMessage_fileupload: "file upload",
        chat_modalMessage_size: "size",
        userAUthorization_choose: "Choose",

        system: "System",
        email_template: "email_template",
        email_template_name: "LastName",
        email_template_content: "Content",
        email_template_html_content: "HTML Content",
        email_template_action: "Actions",
        email_template_search: "Search",
        email_template_save: "Save",
        email_template_cancel: "Cancel",
        email_template_parameters: "Settings",

        cms: "CMS Page",
        cms_internal_name: "Internal Name",
        cms_title: "Title",
        cms_actions: "Actions",
        cms_content: "Content",
        cms_published: "Published",
        cms_public: "Publicly available",
        cms_search: "Search",
        cms_save: "Save",
        cms_cancel: "Cancel",

        tag_value: "value",
        tag_set: "tag set",
        mediathek_generalDefaultTagSelect: "Select keyword",
        daily_news_title: "Title",
        daily_news_content: "Content",
        daily_news_image: "Image",
        daily_news_active: "Active",
        daily_news_date: "Dated",

        events: "Events",
        events_Category_all: "All",
        events_Category_1: "Seminar",
        events_Category_2: "Workshop",
        events_Category_3: "webinar",
        events_datatable_image: "Image",
        events_datatable_name: "name",
        events_datatable_description: "Description",
        events_datatable_category: "category",
        events_datatable_status: "status",
        events_datatable_action: "Actions",
        events_datatable_search: "Search",
        events_datatable_type: "type",
        events_datatable_filter: "Filter",
        events_event_start_dt: "Start Date",
        events_event_end_dt: "End Date",
        events_released: "Release",
        events_submit: "Submit",
        events_cancel: "Cancel",
        events_delete_image: "Delete image",
        events_datatable_sub_header: "Created",

        dashboard_banner_header: "Banner",
        dashboard_banner_sub_header: "created",
        dashboard_banner_title: "title of banner",
        dashboard_banner_description: "Description",
        dashboard_banner_footer: "Banner footer text",
        dashboard_banner_weekly_repeat: "Repeat weekly",
        dashboard_banner_links: "Links",
        dashboard_banner_add_links: "Add a new link",

        diary_template_header: "Template",
        daily_special_header: "Daily special",
        diary_header: "diary",

        diary_alert_title: "Are you sure you want to delete this section?",
        diary_alert_cancel: "Cancel",
        diary_alert_confirm: "DELETE",
        diary_upload_error: "Error! This file cannot be uploaded",
        diary_image_title: "Please enter an image title",
        diary_select_image: "Please select an image",
        diary_upload_button: "Upload",
        diary_cancel_button: "Cancel",
        diary_section_title: "Add section",
        diary_section_subtitle: "Select a section to add",
        diary_question_title: "Choose your question",
        diary_question_categories: "categories",
        diary_question_question: "Questions",
        diary_mark_default: "Mark as default template",
        diary_go_back: "Back",
        diary_use_template: "Use template",
        diary_empty_page_tip: "It looks like there's nothing here! Add a section to start filling in your diary",
        diary_empty_page_button: "Blank page",
        diary_browse_template_button: "Browse templates",
        diary_your_template_button: "Your template",
        diary_dashboard_button: "return to dashboard",
        diary_pick_template: "Pick your template",
        diary_photo_limit: "* You can't select more than 5 images at a time!",
        diary_photo_limit_reached: "You can't upload more than 6 images at a time!",
        diary_template_saved: "Your diary has been successfully saved as a template!",

        community_groups_is_prublished: "Is published ?",

        live_events_datatable_published: "Published",
        live_events_datatable_unpublished: "Unpublished",
        live_events_datatable_released: "Released",
        live_events_datatable_unreleased: "Not released",

        event_page_logo_image: "LogoImage",
        mediathek_rubricConfig_cant_delete_every_rubric_section: "You can't delete every section in the section, you must have at least one section !",
        magazin_config_page_choose_an_article: "Choose an article",
        community_group_request_username: "user",

        limit_upload_files: "You cannot exceed the limit of allowed images",
        unblock: "unlock",
        block: "Lock out",
        cant_manage_post: "You can only manage your contribution",
        team_sideBarTitle: "Team",
        team_title: "Team",

        add_note: "Add note here",
        create_group: "Save",
        cant_manage_post: "You can only manage your post",
        comment_abuse_resolve: "Resolve message",
        comment_abuse_delete: "Delete comment",
        post_abuse_resolve: "Resolve post",
        post_abuse_delete: "Delete post",

        start_date_is_in_future: "Your start date is in the future compared to the end date, please select a valid start date!",
        end_date_is_in_past: "Your end date is after the start date, please select a valid end date!",
        loading: "Loading...",
        sucess_message_toast: "Data saved successfully",
        error_message_toast: "Error, data not saved",
        url_is_incorrect: "The link is not valid",
        mediathek_time_is_incorrect: "Period is not valid !",
        academy_sorting: "Sorting",
        alle: "All",

        daily_set_sideBarTitle: "Daily Sets",
        daily_set_title: "Daily set",
        daily_set_name: "Name",
        daily_set_description: "Description",
        daily_set_game_rules: "Game rules",
        daily_set_image: "Back image",
        pending: "Pending",
        approved: "Approved",
        resolved: "Resolved",
        cancelled: "Cancelled",
        inactive: "Inactive",
        chat_search_member: "Search",
        chat_add_member: "Add a member",
        support_sideBarTitle: "Support",
        category_name: "Name",
        support_question: "Question",
        support_response: "Response",

        daily_set: "DailySets",
        daily_card: "Daily card",
        daily_cards: "DailyCards",

        dashboard_sections: "Sections",
        dashboard_onbording: "Onboarding Message",
        dashboard_bookclub: "Bücherclub",
        dashboard_areaOfHappiness: "Area of happiness",

        sections_color: "Color",
        sections_link: "Link",
        sections_text: "Text",

        area_of_happiness_relationship: "Fulfilled relationships",
        area_of_happiness_enjoyment_of_life: "Enjoyment of life",
        area_of_happiness_inner_peace: "Inner peace",
        area_of_happiness_autonomy: "Autonomy",
        area_of_happiness_prosperity: "Prosperity",

        tag: "Tag",
        bucherclub: "Book club",

        daily_book_module_config_question: "Question",
        daily_book_module_config_emojis: "Emojis",
        daily_book_module_config_emoji_set: "Emojis Set",
        daily_book_module_config_question_title: "Title",

        daily_book_module_config_emojis_set_1: "Emojis Set 1",
        daily_book_module_config_emojis_set_2: "Emojis Set 2",
        daily_book_module_config_emojis_set_3: "Emojis Set 3",
        daily_book_module_config_emojis_set_4: "Emojis Set 4",
        daily_book_module_config_emojis_set_add: "Add emoji",
        daily_book_module_config_emojis_set_clear: "Clear",

        daily_book_module_config_daily_checkin_title: "Daily Checkin",
        daily_book_module_config_daily_checkin_name: "Daily-checkin name",
        daily_book_module_config_daily_checkin_options: "Daily-checkin options",
        daily_book_module_config_daily_checkin_options_name: "Daily-checkin option name",
        daily_book_module_config_emoji_set_length: "You must select 6 emojis.",
        tag_emoji: "Emoji",
        academy_course_title: "Course Title",
        yes: "Yes",
        no: "No",
        roles: "Roles",
        admin : 'Admin',
        live_event_manager : 'Live event manager',
        community_manager : 'Community manager',
        tdm_manager : 'TDM manager',
        academy_manager : 'Academy manager',
        magazin_manager : 'Magazin manager',
        mediathek_manager : 'Mediathek manager',
        meditation_manager : 'Meditation manager',
        experts_manager : 'Experts manager',
        free_member: "Free member",
        chat_group: "Chat Group",
        chat_group_reported: "Reported chat group",
        images: "Image",
        files: 'File',
        audios: 'Audio',
        daily_book_module_config_spcial_days: "Special days",
        daily_book_module_config_spcial_days_description: "Description",
        daily_book_module_config_spcial_days_day: "Day",
        module_config_section_manually: "Would you like to enter ?",
        login_invalid_bad_user_or_password: "Invalid username or password",
        live_events_event_duplicate: "Duplicate event",
        sections_text_color: "text color",
        register_password_needs: "Your password must contain the following requirements",
        register_request_ABC: "Capital letters",
        register_request_abc: "Lowercase letters",
        register_request_special_characters: "Special characters",
        weak: "Weak",
        okay: "Okay",
        good: "Good",
        strong: "Strong",
        too_short: "Too short",
        no_match_password: "Password don't match",
        user_already_exists: "User already exists"
    }
}
